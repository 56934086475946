(function($) {

    $(document).ready(function() {

        // This button will increment the value
        $('.site-search__link').click(function(e){
            e.preventDefault();

            var form = $('.site-search form');

            form.toggleClass( 'is-visible' );
        });

    });

})(jQuery);