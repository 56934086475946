(function($) {

    setAbsoluteHeaderOffset();

    $(window).resize(function() {
        setAbsoluteHeaderOffset();
    });

    /**
     * Users can adjust the site header height via the settings page.
     * Users can also set the site header to be overlayed on top of
     * hero images and page titles.
     */
    function setAbsoluteHeaderOffset() {
        var $header = $('.site-header--absolute'),
            $offset_elements = $('[data-offset-header="true"]');

        if ( $offset_elements.is('*') && $header.is('*') ) {
            $offset_elements.css( 'padding-top', 0 );
            var $header_height = parseInt( $header.outerHeight() );
            var $element_padding = parseInt( $offset_elements.css('padding-top') );
            $offset_elements.css( 'padding-top', $header_height + $element_padding );
        }
    }

})(jQuery);