(function($) {

    var siteHeader = $( '.site-header--fixed' ),
        siteHeaderClone = siteHeader.clone(true).appendTo('.off-canvas-content');

    // Remove the transparent class because the cloned header shouldn't be transparent
    siteHeaderClone.removeClass( 'site-header--transparent' );

    // Add a separate class to the clone and hide it for now
    siteHeaderClone.addClass( 'clone fixed' ).fadeOut();

    /**
     * When not at the very top of the page, a class is added to the header
     * element. The CSS makes it fixed at the top of the page so the user
     * always has access to the header.
     */
    function shrinkHeader( element ) {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = siteHeader.data( 'shrink-on' );

        if (distanceY > shrinkOn) {
            element.fadeIn();
        } else {
            element.fadeOut();
        }
    }

    window.onload = shrinkHeader( siteHeaderClone );

    window.addEventListener( 'scroll', function(e){
        shrinkHeader( siteHeaderClone );
    } );

})(jQuery);